<template>
    <div class="mb-5">
        <div class="__main_card_container">
            <div class="_main_card_header">
                <div class="__header_inner admin_header_padding">
                    <h2>
                        Industries
                    </h2>
                    <div class="side_nav_wrapper">
                        <div class="__side_nav mb-3">
                            <button class="create_notificaton" @click="openModal">
                                Create New Industry
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="main_card_body">
                <div class="row">
                    <div class="col-md-12" v-if="loading">
                        <div class="row mt-5 pt-5">
                            <div class="col-md-12" v-loading="loading">

                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 my-4" v-if="!loading">
                        <div class="d-flex justify-content-end">
                            <div class="form_contain">
                                <el-input placeholder="search industry" class="search_item" style="height: 40px!important;"
                                    v-model="search">
                                    <i slot="prefix" style="padding-to:2em;" class="el-input__icon el-icon-search"></i>
                                </el-input>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12" v-if="!loading">
                        <el-table :data="displayData" :header-cell-style="{ background: 'rgba(212, 216, 226, 0.2)' }"
                            style="width: 100%" :empty-text="'No Data Available'" height="55vh">
                            <el-table-column label="Industry Name" prop="industry_name" width="">
                            </el-table-column>
                            <el-table-column label="Created On">
                                <template slot-scope="scope">
                                    <p>
                                        {{ moment(scope.row.end_date).format('ll') }}
                                    </p>
                                </template>
                            </el-table-column>

                            <el-table-column label="Action" width="200">
                                <template slot-scope="scope">
                                    <button id="enter__account" class="approve actionBtn" @click="editIndustry(scope)">
                                        Update
                                    </button>
                                    <button class="text-danger actionBtn" @click="deleteIndustry(scope)">
                                        Delete
                                    </button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div class="col-md-12" style="text-align: center; margin-top:2em;">
                        <el-pagination background layout="prev, pager, next" @current-change="handleCurrentChange"
                            :page-size="pageSize" :total="total">
                        </el-pagination>
                    </div>
                </div>
            </div>
            <!-- CREATE AND EDIT INDUSTRIES MODAL -->
            <div class="modal fade" id="industries" tabindex="-1" role="dialog" aria-labelledby="contact"
                aria-hidden="true">
                <div class="modal-dialog  modal-dialog-centered " role="document">
                    <div class="modal-content d-flex align-items-center" v-if="success">
                        <successMessage :message="success" />
                        <button @click="hideModal" class="__close__success__modal">Close</button>
                    </div>
                    <div class="modal-content pb-3" v-if="!success">
                        <div class="modal-header pt-4 pb-2">
                            <h5 class="modal-title " id="exampleModalLabel">
                                {{ payload.header }}
                            </h5>
                        </div>
                        <div class="modal-body m-0">
                            <div class="row justify-content-center">
                                <div class="col-md-12 mb-5">
                                    <el-form :model="formData" :rules="rules" ref="ruleForm"
                                        @submit.prevent.native="checkEnter">
                                        <div class="row justify-content-center">
                                            <div class="col-md-12">
                                                <div class="form_input_container">
                                                    <el-form-item label="Industry Name" prop="industry_name">
                                                        <el-input type="text" autocomplete="off"
                                                            placeholder="Enter industry name"
                                                            v-model.number="formData.industry_name"></el-input>
                                                    </el-form-item>
                                                </div>
                                            </div>
                                        </div>
                                    </el-form>
                                </div>
                            </div>
                            <div class="row justify-content-center mb-4">
                                <div class="col-md-12 modal__footer__btns d-flex justify-content-between">
                                    <button style="width:123px" class="cancel" @click="hideModal">Cancel</button>
                                    <el-button type="primary" :loading="loadingAction" id="cancelinput"
                                        @click="submitAction('ruleForm')">
                                        {{ payload.header == 'Edit Industry' ? 'Edit' : 'Create' }} Industry
                                    </el-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- END CREATE AND EDIT INDUSTRIES MODAL -->
            <ActionModal ref="actionModal" :payload="payload" />
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import $ from 'jquery'
import store from '@/state/store.js'
import appConfig from '@/app.config'

export default {
    page: {
        title: 'Industries',
        meta: [{ name: 'description', content: appConfig.description }],
    },
    name: 'admin-industries',
    data() {
        return {
            loading: false,
            payload: {
                type: '',
                header: '',
                data: {}
            },
            success: '',
            error: '',
            loadingAction: false,
            formData: {
                industry_name: ''
            },
            rules: {
                industry_name: [
                    { required: true, message: 'Please Industry name is required.', trigger: 'change' },
                ],
            },
            page: 1,
            pageSize: 20,
            total: 0,
            search: ''
        }
    },
    methods: {
        handleCurrentChange(val) {
            this.page = val;
        },
        submitAction(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.loadingAction = true
                    this.success = ''
                    if (this.payload.header == 'Edit Industry') {
                        let { row, $index } = this.payload.data
                        return store.dispatch('admin/industries/editIndustry', {
                            industry_name: this.formData.industry_name,
                            industry_id: row.id,
                            key: $index
                        })
                            .then(res => {
                                this.loadingAction = false
                                this.success = res
                            }).catch(err => {
                                this.loadingAction = false
                                if (err?.response?.data == undefined) {
                                    this.errors = "Network error try again."
                                }
                            })
                    } else {
                        return store.dispatch('admin/industries/createIndustry', this.formData)
                            .then(message => {
                                this.loadingAction = false
                                this.success = message
                            }).catch(err => {
                                this.loadingAction = false
                                if (err?.response?.data == undefined) {
                                    this.errors = "Network error try again."
                                }
                            })
                    }

                } else {
                    this.loadingAction = false;
                    return false;
                }
            });

        },
        hideModal() {
            $('#industries').modal('hide')
            this.error = ''
            this.success = ''
            this.loading = false
            this.formData = {
                industry_name: ''
            }
        },
        openActionModal() {
            let element = this.$refs.actionModal.$el
            $(element).modal('show')
        },
        openIndustryModal() {
            $('#industries').modal('show')
        },
        editIndustry(data) {
            this.payload = {
                type: 'edit',
                data: data,
                header: 'Edit Industry'
            }
            this.formData.industry_name = data.row.industry_name
            this.openIndustryModal()
        },
        deleteIndustry(data) {
            this.payload = {
                message: 'Are you sure you want to delete this industry?',
                action: 'deleteIndustry',
                actionMessage: 'Delete',
                data: data,
                iconType: 'delete',
                index: data.$index
            }
            this.openActionModal()
        },
        openModal() {
            this.payload = {
                type: 'new',
                header: 'Create New Industry'
            }
            this.openIndustryModal()
        },
    },
    computed: {
        tableData() {
            return store.getters['admin/industries/industries']
        },
        searchingTableData() {
            if (!this.search) {
                this.total = this.tableData.length;
                return this.tableData;
            }
            this.page = 1;
            return this.tableData.filter(data => data.industry_name.toLowerCase().includes(this.search.toLowerCase()));
        },
        displayData() {
            this.total = this.searchingTableData.length;
            return this.searchingTableData.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page);
        },
    },
    mounted() {
        this.loading = true
        store.dispatch('admin/industries/getIndustryReporting')
            .finally(() => this.loading = false)
    }
}
</script>

<style scoped>
#enter__account {
    color: #F7921C;
}

.actionBtn {
    outline: none;
    border: none;
    background: transparent;
    font-family: 'Graphik Regular';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;
}

.create_notificaton {
    font-family: 'Graphik Regular';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 159.3%;
    text-align: center;
    color: #FFFFFF;
    background: #F7921C;
    border-radius: 5px;
    outline: none;
    border: none;
    width: 170px;
    height: 38px;
}
</style>